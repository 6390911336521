import React, { useEffect, useMemo, useState } from 'react';
import { getCsrfToken } from 'next-auth/react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';
import {
  FormErrors,
  getIntlErrorMessage,
  handleFormChange,
  setResponseErrorMessages,
  validateEmail,
  validateRequiredFields,
} from './use-form-validation';
import { RequestPasswordResetRequest } from '@models/http/request-password-reset-request';
import { fetchPost } from '@fetch/helpers';

interface ForgotPasswordData extends RequestPasswordResetRequest {}

interface ValidationParams {
  requiredFields: (keyof ForgotPasswordData)[];
  values: ForgotPasswordData;
}

const validate = ({ requiredFields, values }: ValidationParams) => {
  let errors = validateRequiredFields(requiredFields, values);
  const email = validateEmail(values.email);
  if (email) {
    errors.email = email;
  }
  const hasErrors = Object.values(errors).some((e) => e && e.type);
  return { errors, hasErrors };
};

const useForgotPasswordForm = () => {
  const requiredFields: (keyof ForgotPasswordData)[] = useMemo(() => {
    return ['email'];
  }, []);
  const formData = {
    email: '',
  };
  const [values, setValues] = useState(formData);
  const [errors, setErrors] = useState<FormErrors<ForgotPasswordData>>();
  const [hasErrors, setFormHasErrors] = useState(false);
  const [submitError, setSubmitError] = useState<string[]>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const intl = useIntl();
  const router = useRouter();

  useEffect(() => {
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
  }, [requiredFields, values]);

  const reset = () => {
    setValues(formData);
    setSubmitSuccessful(false);
    setIsSubmitting(false);
    setHasSubmitted(false);
  };

  const handleChange = handleFormChange({ values, setValues });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    /* istanbul ignore next */
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHasSubmitted(true);
    setSubmitError(undefined);
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
    if (_errors.hasErrors) {
      setSubmitError([getIntlErrorMessage('formValidationErrors', intl)]);
      setIsSubmitting(false);
      return;
    }

    const csrfToken = await getCsrfToken();
    const response = await fetchPost({
      url: '/api/v3/users/password_resets',
      data: { ...values, csrfToken },
    });
    setIsSubmitting(false);
    if (response.ok) {
      setSubmitSuccessful(true);
      router.push({
        pathname: '/otp-email',
        query: {
          username: values.email,
          callbackUrl: '/account/change-password',
        },
      });
    } else {
      setResponseErrorMessages({ response, setErrors, setSubmitError, intl });
    }
  };

  return {
    handleChange,
    values,
    handleSubmit,
    errors,
    hasErrors,
    hasSubmitted,
    isSubmitting,
    submitError,
    submitSuccessful,
    reset,
    setValues,
  };
};

export default useForgotPasswordForm;
