import type { GetServerSideProps, NextPage } from 'next';
import { NextRouter, useRouter } from 'next/router';
import { useEffect } from 'react';
import Homepage from '../components/templates/home/home';
import { fetchPrivilegedClientOnlyRoute, logError } from '@fetch/helpers';
import { OfferingOfTheWeek } from '@models/offering-of-the-week';

export const runOldRedirects = (router: NextRouter) => {
  const canRun = typeof window !== 'undefined';
  if (!canRun) {
    return false;
  }
  if (window.location.hash) {
    const matchesTreasurer = window.location.hash.match(
      /#\/org\/([^/]*)\/manage.*/
    );
    if (matchesTreasurer && matchesTreasurer.length >= 2) {
      window.location.replace(
        `https://adventistgiving.org/#/org/${matchesTreasurer[1]}/manage`
      );
      return true;
    }
    const matchesDonate = window.location.hash.match(/#\/org\/([^/]*).*/);
    if (matchesDonate && matchesDonate.length >= 2) {
      router.replace(`/donate/${matchesDonate[1]}`);
      return true;
    }
    return false;
  }
};

const Home: NextPage = ({ offeringOfTheWeek }: any) => {
  const router = useRouter();

  useEffect(() => {
    runOldRedirects(router);
  }, [router]);
  return <Homepage offeringOfTheWeek={offeringOfTheWeek} />;
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  let orgId: string = '';
  let data = Object.keys(context.query).filter(
    (k) => k.toLowerCase() === 'orgid'
  );
  if (data) orgId = context.query[data[0]] as string;
  if (orgId) {
    return {
      redirect: {
        destination: `/donate/${orgId}`,
        permanent: false,
      },
    };
  }

  let offeringOfTheWeek: OfferingOfTheWeek | any = {};
  const response = await fetchPrivilegedClientOnlyRoute({
    url: `${process.env.API_URL}/v3/offering_of_the_week`,
    req: context.req as any,
  });
  if (response.ok) {
    try {
      offeringOfTheWeek = await response.json();
    } catch (err) {
      logError('Unable to parse /api/v3/offering_of_the_week', err);
    }
  }

  return {
    props: { offeringOfTheWeek },
  };
};

export default Home;
