import Image from 'next/image';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import Slider from 'react-slick';
import { defineMessages, useIntl } from 'react-intl';
import Card from '../../molecules/card/card';
import SliderHome2Control from '../../molecules/slider-home-2-control/slider-home-2-control';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { linkBasicUnderline } from '@components/atoms/link/link';

const messages = defineMessages({
  slideTithe1: {
    id: 'uAi+fE',
    defaultMessage:
      'Tithe is holy and belongs to the Lord, the owner of all our material possessions and very lives (Levitcus 27:30).',
    description:
      'Text that appears on homepage for the "Tithe" slide (paragraph 1).',
  },
  slideTithe2: {
    id: 'gItLar',
    defaultMessage:
      'God commands us to return a tithe, the first ten percent of our income. Returning tithe helps to support the spreading of the gospel by supporting pastors, education, and evangelism. <a>Learn more about how tithe is allocated</a>.',
    description:
      'Text that appears on homepage for the "Tithe" slide (paragraph 2).',
  },
  slideOffering1: {
    id: 'DoCOBY',
    defaultMessage:
      'Offerings are anything you choose to give beyond your tithe. It is an expression of gratitude to God for His blessings and a way to bless the local and world community.',
    description:
      'Text that appears on homepage for the "Offering" slide (paragraph 1).',
  },
  slideOffering2: {
    id: 'olc9Y4',
    defaultMessage:
      'Offerings support church needs, including ministries, operations, and community outreach.',
    description:
      'Text that appears on homepage for the "Offering" slide (paragraph 2).',
  },
  slideBible: {
    id: 'SAtNOA',
    defaultMessage:
      "Returning tithe and offerings is mentioned several times throughout the Bible. For example, Abraham gave a tithe to the priest Melchizedek (Genesis 14:18-20), and a widow gave an offering of two copper coins at the temple, earning Jesus's recognition (Luke 21:1-4). Additionally, Malachi 3:8-12 shows that returning a tithe is an act of worship, that we trust God to provide for us, not ourselves.",
    description:
      'Text that appears on homepage for the "From the Bible" slide.',
  },
  slideWhyGive: {
    id: 'MkidvK',
    defaultMessage:
      'As Christians, we are to be stewards of what God has entrusted to us, whether it is money, time, possessions, or abilities. When we exercise good stewardship, we remind ourselves that God is the ultimate Provider. Giving helps us grow in faithfulness and loyalty to God while also allowing us to practice accountability and trust when using the blessings God gives us.',
    description: 'Text that appears on homepage for the "Why Give" slide.',
  },
  slideHowMuch1: {
    id: 'CIjUWV',
    defaultMessage:
      'For tithe, the biblical directive is to return 10% of our income to the Lord. For Offerings, we give as the Lord has blessed us from a grateful heart.',
    description:
      'Text that appears on homepage for the "How Much to Give" slide (paragraph 1).',
  },
  slideHowMuch2: {
    id: 's6LABZ',
    defaultMessage:
      'For further guidance, refer to the <a>Personal Giving Plan</a>, which outlines suggested amounts to support each offering category.',
    description:
      'Text that appears on homepage for the "How Much to Give" slide (paragraph 2).',
  },
  slideWhereItGoes: {
    id: 'ge4SGE',
    defaultMessage:
      "A majority of your tithe and offerings support your local community while also furthering the world church's mission of spreading the gospel. Tithe specifically supports our pastors, educators, administrators, and evangelists in their work. Offerings support the local church, region, and world needs in ministry, operations, and education. <a>Learn more about how donations are allocated</a>.",
    description: 'Text that appears on homepage for the "Where it Goes" slide.',
  },
  slideTitleTithe: {
    id: 'BVnTBp',
    defaultMessage: 'Tithe',
    description: 'Title for slide on homepage.',
  },
  slideTitleOfferings: {
    id: 'XBJDi1',
    defaultMessage: 'Offerings',
    description: 'Title for slide on homepage.',
  },
  slideTitleBible: {
    id: 'Bu4HLV',
    defaultMessage: 'From the Bible',
    description: 'Title for slide on homepage.',
  },
  slideTitleWhyGive: {
    id: '+JZ86H',
    defaultMessage: 'Why Give',
    description: 'Title for slide on homepage.',
  },
  slideTitleHowMuch: {
    id: 'En+pJe',
    defaultMessage: 'How Much to Give',
    description: 'Title for slide on homepage.',
  },
  slideTitleWhereItGoes: {
    id: 'XS4RyB',
    defaultMessage: 'Where It Goes',
    description: 'Title for slide on homepage.',
  },
});

const SliderHome2 = () => {
  const intl = useIntl();
  const Container = useRef(null);
  const SliderCustom = useRef(null);
  let SliderSlick = useRef<Slider | null>(null);
  const [current, setCurrent] = useState(0);
  const [currentMobile, setCurrentMobile] = useState(1);

  interface SliderData {
    image: string;
    title: string;
    paragraph: any[];
  }
  const sliderData: SliderData[] = [
    {
      image: '/noprecache/img/slide-tithe.jpg',
      title: intl.formatMessage(messages.slideTitleTithe),
      paragraph: [
        intl.formatMessage(messages.slideTithe1),
        intl.formatMessage(messages.slideTithe2, {
          a: (text: ReactNode) => (
            <a
              className={`${linkBasicUnderline}`}
              href="https://www.nadadventist.org/news/follow-funds-simple-explanation-how-tithe-allocated"
            >
              {text}
            </a>
          ),
        }),
      ],
    },
    {
      image: '/noprecache/img/slide-offering.jpg',
      title: intl.formatMessage(messages.slideTitleOfferings),
      paragraph: [
        intl.formatMessage(messages.slideOffering1),
        intl.formatMessage(messages.slideOffering2),
      ],
    },
    {
      image: '/noprecache/img/slide-bible.jpg',
      title: intl.formatMessage(messages.slideTitleBible),
      paragraph: [intl.formatMessage(messages.slideBible)],
    },
    {
      image: '/noprecache/img/slide-why-give.jpg',
      title: intl.formatMessage(messages.slideTitleWhyGive),
      paragraph: [intl.formatMessage(messages.slideWhyGive)],
    },
    {
      image: '/noprecache/img/slide-how-much.jpg',
      title: intl.formatMessage(messages.slideTitleHowMuch),
      paragraph: [
        intl.formatMessage(messages.slideHowMuch1),
        intl.formatMessage(messages.slideHowMuch2, {
          a: (text: ReactNode) => (
            <a
              className={`${linkBasicUnderline}`}
              href="https://www.personalgivingplan.com/"
            >
              {text}
            </a>
          ),
        }),
      ],
    },
    {
      image: '/noprecache/img/slide-where-it-goes.jpg',
      title: intl.formatMessage(messages.slideTitleWhereItGoes),
      paragraph: [
        intl.formatMessage(messages.slideWhereItGoes, {
          a: (text: ReactNode) => (
            <a
              className={`${linkBasicUnderline}`}
              href="https://www.nadadventist.org/news/follow-funds-simple-explanation-how-tithe-allocated"
            >
              {text}
            </a>
          ),
        }),
      ],
    },
  ];

  const changeSlide = (key: string) => {
    if (key == 'LEFT') {
      setCurrent(current - 1);
    } else if (key == 'RIGHT') {
      setCurrent(current + 1);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setParallax = () => {
    const q = gsap.utils.selector(Container);
    let slides = q('.slide');
    let next = slides[current + 1];
    let before = slides[current - 1];
    if (next) {
      let selectItem = gsap.utils.selector(next);
      gsap.to(selectItem('.slide-text'), {
        ease: 'power2.out',
      });
      gsap.to(selectItem('.src_image'), {
        scale: 1.3,
        ease: 'power2.out',
      });
    }
    if (before) {
      let selectItem2 = gsap.utils.selector(before);
      gsap.to(selectItem2('.slide-text'), {
        duration: 1.5,
        x: '-35vw',
        opacity: 0,
        ease: 'power2.out',
      });
      gsap.to(selectItem2('.src_image'), {
        scale: 1.3,
        duration: 1.5,
        opacity: 0,
        ease: 'power2.out',
      });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setContainer = () => {
    const q = gsap.utils.selector(Container);
    let slides = q('.slide');
    let actual = slides[current];
    let q2 = gsap.utils.selector(actual);
    if (SliderCustom.current) {
      gsap.to(Container.current, {
        duration: 1.5,
        x: -(SliderCustom.current as any).offsetWidth * current,
        ease: 'power2.out',
        opacity: 1,
      });
      gsap.to(q2('.slide-text'), {
        duration: 1.5,
        x: 0,
        ease: 'power2.out',
        opacity: 1,
      });

      gsap.to(q2('.src_image'), {
        scale: 1,
        duration: 1.5,
        ease: 'power2.out',
        opacity: 1,
      });
    }
  };

  useEffect(() => {
    setContainer();
    setParallax();
  }, [current, setContainer, setParallax]);
  return (
    <>
      <section>
        <div className="lg:hidden pt-24 bg-nad-blue">
          <div className="container flex pb-12 flex flex-col ">
            <SliderHome2Control showTitle showControl={false} />
            <div>
              <Slider
                ref={SliderSlick}
                dots={false}
                infinite={false}
                speed={500}
                arrows={false}
                slidesToShow={1}
                slidesToScroll={1}
                afterChange={(currentSlide) => {
                  setCurrentMobile(currentSlide + 1);
                }}
              >
                {sliderData.map((item, i) => (
                  <div className="flex flex-col" key={i}>
                    <div className="relative aspect-video">
                      <Image
                        sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
                        fill
                        src={item.image}
                        className="object-cover"
                        alt=""
                      />
                    </div>
                    <Card
                      variant="default"
                      title={`${i + 1}. ${item.title}`}
                      paragraphs={item.paragraph}
                    />
                  </div>
                ))}
              </Slider>
              <SliderHome2Control
                totalItems={sliderData.length}
                current={currentMobile}
                // changeSlide={changeSlide}
                showTitle={false}
                showControl={true}
                customNext={() => {
                  currentMobile < sliderData.length &&
                    setCurrentMobile(currentMobile + 1);

                  SliderSlick &&
                    SliderSlick.current &&
                    SliderSlick.current.slickNext();
                }}
                customPrev={() => {
                  currentMobile > 1 && setCurrentMobile(currentMobile - 1);
                  SliderSlick &&
                    SliderSlick.current &&
                    SliderSlick.current.slickPrev();
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="hidden lg:block overflow-hidden pt-24"
          style={{
            background: 'linear-gradient(180deg, #003B5C 75%, #FFFFFF 25%)',
          }}
        >
          <div className="container mx-auto">
            <div className="w-5/6 mx-auto flex flex-row">
              <div className="flex flex-col justify-center z-10">
                <SliderHome2Control
                  totalItems={sliderData.length}
                  current={current}
                  changeSlide={changeSlide}
                  showControl
                  showTitle
                />
              </div>
              <div className="w-auto">
                <div className="mr-break-out">
                  <div
                    className="relative flex left-0 top-0 w-full"
                    ref={Container}
                  >
                    {sliderData.map((item, i) => (
                      <div
                        className="slide relative px-5.5vw"
                        ref={SliderCustom}
                        key={i}
                      >
                        <div className="slide-text top-1/2 left-[55%] xl:left-[65%] w-4/5 absolute -translate-y-1/2 z-10">
                          <div className="h-4/5">
                            <Card
                              variant="default"
                              title={`${i + 1}. ${item.title}`}
                              paragraphs={item.paragraph}
                            />
                          </div>
                        </div>
                        <div className="w-[500px] overflow-hidden relative h-[689px]">
                          <Image
                            className="src_image object-cover"
                            fill
                            sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
                            src={item.image}
                            style={{
                              opacity: 1,
                              transform: 'matrix(1, 0, 0, 1, 0, 0)',
                            }}
                            alt=""
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SliderHome2;
