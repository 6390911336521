import Message from '../message/message';

interface Props {
  errors?: string[];
  className?: string;
}

const FormErrorMessage = ({ errors, ...props }: Props) => {
  if (!errors) {
    return <></>;
  }

  return (
    <Message type="danger" textAlign="left" data-testid="form-error" {...props}>
      <div className="space-y-2 text-center">
        {errors.map((error) => (
          <div key={error}>{error}</div>
        ))}
      </div>
    </Message>
  );
};
export default FormErrorMessage;
