import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import InputTooltip, {
  InputTooltipPlacement,
  TooltipTextAlign,
} from '../input-tooltip/input-tooltip';
import VerificationInput, { VerificationInputProps } from './input-vi';
import { Text } from '@components/atoms/text/text';
import { FormError, getErrorMessage } from '@hooks/use-form-validation';

export type VerificationVariant = 'normal' | 'small';

export interface InputTextProps extends VerificationInputProps {
  label?: string;
  'data-testid'?: string;
  error?: FormError;
  tooltip?: ReactNode;
  tooltipLabel?: string;
  tooltipPlacement?: InputTooltipPlacement;
  tooltipTextAlign?: TooltipTextAlign;
  tooltipAriaLabel?: string;
  helpText?: string;
  helpTextTop?: string | ReactNode;
  labelClasses?: string;
  required?: boolean;
  name?: string;
  id?: string;
  idPreface?: string;
  className?: string;
  variant?: VerificationVariant;
  length?: number;
  autoFocus?: boolean;
}

const InputVerification = ({
  error,
  tooltip,
  tooltipLabel,
  tooltipPlacement = 'responsive',
  tooltipTextAlign = 'left',
  helpText,
  helpTextTop,
  className = '',
  labelClasses = '',
  tooltipAriaLabel = '',
  idPreface,
  label,
  value,
  length = 6,
  variant = 'normal',
  autoFocus = true,
  ...props
}: InputTextProps) => {
  if (tooltipLabel) {
    tooltipPlacement = 'topRightOnly';
  }
  const intl = useIntl();
  const borderColor = error ? 'border-nad-scarlett' : 'border-nad-alps-night-2';
  const id = props.id ? props.id : props.name ? props.name : undefined;
  const heightClasses: Record<VerificationVariant, string> = {
    normal: 'h-20',
    small: 'h-14',
  };
  const lhClasses: Record<VerificationVariant, string> = {
    normal: '!leading-[5rem]',
    small: '!leading-[3.5rem]',
  };
  props.id = id;
  return (
    <div
      className="adgv-verification-input text-left relative"
      id={`vi-container-${id}`}
    >
      {label && (
        <label className={`text-s ${labelClasses} block mb-1`} htmlFor={id}>
          <span className="text-nad-alps-night font-sans">{label}</span>
          {props.required && (
            <span className="text-nad-scarlett text-s">*</span>
          )}
        </label>
      )}
      {helpTextTop}
      <VerificationInput
        placeholder=""
        classNames={{
          container: `${heightClasses[variant]}`,
          character: `border ${borderColor} ${lhClasses[variant]}`,
          characterInactive: `bg-nad-white`,
          characterSelected: 'outline outline-offset-2 outline-nad-ming',
        }}
        length={length}
        autoFocus={autoFocus}
        value={value}
        {...props}
      />
      {helpText && (
        <Text size="XS" color="nad-alps-night">
          {helpText}
        </Text>
      )}
      {tooltip && (
        <InputTooltip
          style="default"
          placement={tooltipPlacement}
          label={tooltipLabel}
          textAlign={tooltipTextAlign}
          aria-label={tooltipAriaLabel}
        >
          {tooltip}
        </InputTooltip>
      )}
      {error && error.type && (
        <Text
          size="S"
          color="nad-scarlett"
          data-testid={
            props['data-testid'] ? `${props['data-testid']}-error` : ''
          }
        >
          {getErrorMessage(intl, error, label)}
        </Text>
      )}
    </div>
  );
};

export default InputVerification;
