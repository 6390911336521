import React, { ReactNode } from 'react';
import { Text } from '../../atoms/text/text';
import { HeadingUppercase } from '@components/atoms/heading-uppercase/heading-uppercase';

interface Props {
  title?: string;
  paragraphs?: string[];
  className?: string;
  variant: 'default' | 'bordered' | 'bordered-opacity' | 'lightblue';
  children?: ReactNode;
}

const Card = ({
  title,
  paragraphs = [],
  className = '',
  variant,
  children,
}: Props) => {
  return (
    <>
      {variant == 'default' && (
        <div
          className={`p-12 lg:p-20 lg:pt-24 lg:pb-36 bg-nad-white lg:h-full flex flex-col justify-center ${className}`}
        >
          <HeadingUppercase
            variant="h4"
            as="h4"
            color="nad-blue"
            className="mb-4"
          >
            {title}
          </HeadingUppercase>
          <div className="space-y-8">
            {paragraphs.map((paragraph, idx) => (
              <Text
                key={`paragraph_${idx}`}
                size="S"
                color="nad-alps-night"
                className="font-serif"
              >
                {paragraph}
              </Text>
            ))}
          </div>
        </div>
      )}
      {variant == 'bordered-opacity' && (
        <div
          className={` w-full border-4  border-nad-alps-night-2-500   py-10 px-14 `}
        >
          {children}
        </div>
      )}

      {variant == 'bordered' && (
        <div
          className={` w-full border-4  border-nad-alps-night-2  py-10 px-14 `}
        >
          {children}
        </div>
      )}
      {variant == 'lightblue' && (
        <div
          className={`w-full border-4 border-nad-light-blue py-10 md:px-14 px-6`}
        >
          {children}
        </div>
      )}
    </>
  );
};

export default Card;
