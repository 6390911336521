import { FormattedMessage } from 'react-intl';
import { Text } from '../../atoms/text/text';
import { Heading } from '@components/atoms/heading/heading';
import { Link } from '@components/atoms/link/link';

interface Props {
  includesCTA?: boolean;
  includeGuestCTA: boolean;
}

const HomepageWelcome = ({ includesCTA = true, includeGuestCTA }: Props) => {
  return (
    <>
      <Heading variant="h1" as="h1" color="nad-blue" className="leading-none">
        <FormattedMessage
          defaultMessage="Welcome"
          id="HVsjOV"
          description="Home page welcome title"
        />
      </Heading>
      {includesCTA && (
        <Text size="S" color="nad-alps-night" className="font-serif">
          {!includeGuestCTA && (
            <FormattedMessage
              id="iG2vKk"
              defaultMessage="AdventistGiving allows you to easily return tithe and give offerings to your local church."
              description="Home page welcome introduction"
            />
          )}
          {includeGuestCTA && (
            <FormattedMessage
              id="6qhZj5"
              defaultMessage="AdventistGiving allows you to easily return tithe and give offerings to your local church. Sign in below or <a>donate as a guest</a>."
              description="Home page welcome introduction"
              values={{
                a: (text) => (
                  <Link variant="underline" href="/donate">
                    {text}
                  </Link>
                ),
              }}
            />
          )}
        </Text>
      )}
    </>
  );
};

export default HomepageWelcome;
