import { ButtonHTMLAttributes } from 'react';
import { defineMessages } from '@formatjs/intl';
import { useIntl } from 'react-intl';
import { IconChevronLeft, IconChevronRight } from '@components/atoms/icons';
import { Button } from '@components/atoms/button/button';

const ariaSlideMessages = defineMessages({
  next: {
    id: 'vBL7+6',
    defaultMessage: 'next slide',
    description: 'Text for accessibility to help describe buttons for slides',
  },
  prev: {
    id: '4XrhIs',
    defaultMessage: 'previous slide',
    description: 'Text for accessibility to help describe buttons for slides',
  },
});

const ariaPageMessages = defineMessages({
  next: {
    id: 'nxo4Hp',
    defaultMessage: 'next page',
    description:
      'Text for accessibility to help describe buttons for paginated data',
  },
  prev: {
    id: 'wUUfkC',
    defaultMessage: 'previous page',
    description:
      'Text for accessibility to help describe buttons for paginated data',
  },
});

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'prev' | 'next';
  color: 'blue' | 'white';
  ariaType: 'slide' | 'page';
}
const PaginationButton = ({ variant, color, ariaType, ...props }: Props) => {
  const intl = useIntl();
  const buttonClasses =
    color === 'white'
      ? 'hover:bg-nad-white border-nad-white disabled:border-nad-ming focus-visible:ring-nad-ming'
      : 'hover:bg-nad-blue border-nad-blue disabled:border-nad-alps-night-2';
  const iconColors =
    color === 'white'
      ? `group-hover:fill-nad-blue group-disabled:fill-nad-ming`
      : `group-hover:fill-nad-white group-disabled:fill-nad-alps-night-2`;
  const iconColor = props.disabled
    ? 'nad-alps-night-2'
    : color === 'white'
      ? 'nad-white'
      : 'nad-blue';
  const ariaMessages =
    ariaType === 'slide' ? ariaSlideMessages : ariaPageMessages;
  return (
    <Button
      type="button"
      variant="icon"
      className={`group w-12 h-12 flex items-center justify-center border-2 rounded-full disabled:bg-transparent ${buttonClasses}`}
      aria-label={intl.formatMessage(ariaMessages[variant])}
      {...props}
    >
      {variant === 'prev' && (
        <IconChevronLeft color={iconColor} className={iconColors} />
      )}
      {variant === 'next' && (
        <IconChevronRight color={iconColor} className={iconColors} />
      )}
    </Button>
  );
};

export default PaginationButton;
