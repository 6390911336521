import Image from 'next/image';
import { Text } from '../../atoms/text/text';
import { HeadingUppercase } from '../heading-uppercase/heading-uppercase';
import { IconBuild, IconChurch, IconWorld } from '../icons';

export type CardImageIcon = 'church' | 'build' | 'world';

interface Props {
  image: string;
  title: string;
  imageMobile: string;
  icon: CardImageIcon;
  paragraph: string;
}

export const CardImage = (props: Props) => {
  return (
    <div>
      <div className="w-full relative hidden md:block aspect-[393/525]">
        <Image
          fill={true}
          sizes="(max-width: 768px) 100vw, 33vw"
          className="object-cover"
          src={props.image}
          alt=""
        />
      </div>
      <div className="w-full relative block md:hidden">
        <Image
          src={props.imageMobile}
          width="358"
          height="224"
          sizes="(max-width: 768px) 100vw, 33vw"
          style={{
            width: '100%',
            height: 'auto',
          }}
          alt=""
        />
      </div>
      <div className="mt-6">
        <div className="flex md:h-12 py-2 space-x-3">
          <CustomIcon icon={props.icon} />
          <HeadingUppercase
            variant="h5"
            as="h3"
            color="nad-blue"
            className="mt-1"
          >
            {props.title}
          </HeadingUppercase>
        </div>
        <Text size="S" color="nad-alps-night" className="font-serif mt-6">
          {props.paragraph}
        </Text>
      </div>
    </div>
  );
};

const CustomIcon = ({ icon }: { icon: CardImageIcon }) => {
  switch (icon) {
    case 'church':
      return <IconChurch color="nad-blue" className="w-[35px] h-[35px]" />;
    case 'build':
      return <IconBuild color="nad-blue" />;
    case 'world':
      return <IconWorld color="nad-blue" />;
    default:
      return <IconWorld color="nad-blue" />;
  }
};
