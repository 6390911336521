import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LoginSecondFactor from '../login-second-factor/login-second-factor';
import { ForgotPassword } from '../forgot-password/forgot-password';
import InputText from '../../atoms/input-text/input-text';
import { Button } from '../../atoms/button/button';
import useLoginForm from '@hooks/use-login-form';
import { getElementError } from '@hooks/use-form-validation';
import Checkbox from '@components/atoms/checkbox/checkbox';
import FormErrorMessage from '@components/molecules/form-error-message/form-error-message';
import HomepageWelcome from '@components/molecules/homepage-welcome/homepage-welcome';

const LoginFormHomepage = () => {
  const intl = useIntl();
  const {
    values,
    handleChange,
    handleSubmit,
    hasSubmitted,
    errors,
    hasErrors,
    submitError,
    isSubmitting,
    requiresSecondFactor,
    preferredSecondFactor,
  } = useLoginForm({ isOauth: false });
  const [forgotPassword, setForgotPassword] = useState(false);
  const getError = getElementError(errors, hasSubmitted);
  return (
    <>
      {requiresSecondFactor && preferredSecondFactor && values.username && (
        <>
          <HomepageWelcome includesCTA={false} includeGuestCTA={false} />
          <LoginSecondFactor
            className="mt-8 mb-12"
            isOauth={false}
            initialSecondFactor={preferredSecondFactor}
            username={values.username}
          />
        </>
      )}
      {!requiresSecondFactor && (
        <>
          <ForgotPassword
            isOpen={forgotPassword}
            email={values.username}
            close={() => setForgotPassword(false)}
          ></ForgotPassword>
          <form
            method="post"
            onSubmit={handleSubmit}
            data-testid="home-login-form"
            noValidate
          >
            <div className="space-y-6">
              <HomepageWelcome includeGuestCTA />
            </div>
            <div className="mt-6">
              <InputText
                name="username"
                label={intl.formatMessage({
                  id: '5U10vH',
                  defaultMessage: 'Email',
                  description: 'Email form label',
                })}
                data-testid="login-username"
                value={values.username}
                onChange={handleChange}
                error={getError('username')}
                autoComplete="username"
              />
              <div className="mt-6 mb-5">
                <InputText
                  name="password"
                  data-testid="login-password"
                  value={values.password}
                  onChange={handleChange}
                  label={intl.formatMessage({
                    id: 'T2MqHL',
                    defaultMessage: 'Password',
                    description: 'Password form label',
                  })}
                  type={values.showPassword ? 'text' : 'password'}
                  autoComplete={
                    values.showPassword ? 'off' : 'current-password'
                  }
                  error={getError('password')}
                  helpTextTop={
                    <button
                      type="button"
                      className="text-xs underline text-nad-alps-night font-sans float-right cursor-pointer"
                      onClick={() => {
                        setForgotPassword(true);
                      }}
                    >
                      <FormattedMessage
                        id="BfjXiq"
                        defaultMessage="Forgot Password?"
                        description="CTA to open forgot password modal."
                      />
                    </button>
                  }
                />
              </div>
              <div className="flex flex-row justify-between">
                <Checkbox
                  name="stay_signed_in"
                  label={intl.formatMessage({
                    id: 'B4j4C8',
                    defaultMessage: 'Stay signed in',
                    description: 'Stay signed in form label',
                  })}
                  data-testid="login-stay_signed_in"
                  checked={values.stay_signed_in}
                  onChange={handleChange}
                />
                <Checkbox
                  name="showPassword"
                  label={intl.formatMessage({
                    id: 'kMRvN6',
                    defaultMessage: 'Show password',
                    description: 'Label for show password option',
                  })}
                  checked={values.showPassword}
                  onChange={handleChange}
                />
              </div>
              <FormErrorMessage className="mt-4" errors={submitError} />
              <div className="flex gap-2 sm:gap-0 flex-col sm:flex-row items-center mt-5">
                <Button
                  isLoading={isSubmitting}
                  size="small"
                  data-testid="login-submit"
                  disabled={isSubmitting || (hasSubmitted && hasErrors)}
                >
                  <FormattedMessage
                    id="HC1HLX"
                    defaultMessage="Sign In"
                    description="Sign in button"
                  />
                </Button>
                <Button
                  size="small"
                  variant="link"
                  icon="rightArrow"
                  className="sm:!px-4"
                  href="/register"
                  as="link"
                >
                  <FormattedMessage
                    id="kdEm0R"
                    defaultMessage="Create account"
                    description="Create account button"
                  />
                </Button>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default LoginFormHomepage;
