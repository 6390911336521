import { FormattedMessage } from 'react-intl';
import { Heading } from '@components/atoms/heading/heading';
import { HeadingUppercase } from '@components/atoms/heading-uppercase/heading-uppercase';

const VerseQuote = () => {
  return (
    <section className="bg-nad-blue">
      <div className="container">
        <div className="flex items-center justify-start  sm:justify-center py-2">
          <div className="lg:w-5/6 mt-20">
            <div className="border-l-solid border-l-8 pl-8 border-l-nad-ming">
              <Heading
                variant="h4"
                as="h2"
                color="nad-white"
                className="md:text-2xl lg:leading-[3rem]"
              >
                <FormattedMessage
                  id="lbohUs"
                  defaultMessage="Honor the Lord with your wealth and with the firstfruits of all your produce; then your barns will be filled with plenty, and your vats will be bursting with wine."
                  description="Main bible verse that appears on homepage."
                />
              </Heading>
            </div>
            <div className="border-l-solid border-l-8 pl-8 border-l-nad-blue mt-6">
              <HeadingUppercase variant="h5" color="nad-white">
                <FormattedMessage
                  id="ySLbza"
                  defaultMessage="Proverbs 3:9-10"
                  description="Main bible verse citation on homepage."
                />
              </HeadingUppercase>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerseQuote;
