import Image from 'next/image';
import { FormattedMessage } from 'react-intl';
import { useRouter } from 'next/router';
import { Button } from '../../atoms/button/button';
import { Link } from '../../atoms/link/link';
import { Text } from '../../atoms/text/text';
import signupImg from '../../../public/noprecache/img/signup-suggest.jpg';
import { useCurrentUser } from '@hooks/use-user';
import { useHelpscout } from '@hooks/use-helpscout';
import { Heading } from '@components/atoms/heading/heading';

const SignUpSuggest = () => {
  const router = useRouter();
  const { open } = useHelpscout();
  const { data: user } = useCurrentUser();
  const isLoggedIn = user && user.status !== 'guest';
  return (
    <>
      <section className="bg-skyblue ">
        <div className="container flex flex-col-reverse flex-col lg:flex-row">
          <div className="p-30 w-full">
            <div className="relative min-h-full ml-break-out hidden lg:block">
              <Image
                quality={85}
                className="w-3/12 object-cover"
                fill={true}
                sizes="(max-width: 768px) 100vw, 50vw"
                src={signupImg}
                alt=""
              />
            </div>

            <div className="mx-break-out block lg:hidden sm:h-[341px]">
              <Image
                quality={85}
                className="object-cover w-full h-full"
                sizes="(max-width: 768px) 100vw, 50vw"
                src={signupImg}
                alt=""
              />
            </div>
          </div>
          <div className="w-full lg:w-9/12">
            <div className="py-12 lg:p-40 lg:pr-0">
              <Heading variant="h4" as="h2" color="nad-blue">
                {!isLoggedIn && (
                  <FormattedMessage
                    id="14nFTu"
                    defaultMessage="Are you new to AdventistGiving?"
                    description="CTA title on homepage for creating a new account."
                  />
                )}
                {isLoggedIn && (
                  <FormattedMessage
                    id="JJAupN"
                    defaultMessage="Need help with AdventistGiving?"
                    description="CTA title on homepage for accessing the help desk."
                  />
                )}
              </Heading>
              <Text size="S" color="nad-alps-night" className="mt-4 font-serif">
                {!isLoggedIn && (
                  <FormattedMessage
                    id="BbRP81"
                    defaultMessage="AdventistGiving allows you to return your tithe and offerings online from wherever you are, even if you can't attend church. Create an account to start your online giving experience."
                    description="CTA description on homepage for creating a new account"
                  />
                )}
                {isLoggedIn && (
                  <FormattedMessage
                    id="iyNQEG"
                    defaultMessage="Visit the help desk for guidance on how to use AdventistGiving to return your tithe and offerings and for answers to common questions."
                    description="CTA description on homepage for accessing the help desk."
                  />
                )}
              </Text>

              <Button
                className="mt-4"
                width="large"
                onClick={(e) => {
                  if (isLoggedIn) {
                    open();
                  } else {
                    router.push('/register');
                  }
                }}
                data-testid="sign-up-suggest-create-account"
              >
                {!isLoggedIn && (
                  <FormattedMessage
                    id="BJWNma"
                    defaultMessage="Create Account"
                    description="CTA button on homepage for creating a new account."
                  />
                )}
                {isLoggedIn && (
                  <FormattedMessage
                    id="k3rPoK"
                    defaultMessage="Help Desk"
                    description="CTA button on homepage for accessing the help desk."
                  />
                )}
              </Button>
              {!isLoggedIn && (
                <Text
                  size="XS"
                  color="nad-alps-night"
                  className="mt-4 font-sans"
                >
                  <FormattedMessage
                    id="s6epGU"
                    defaultMessage="Have an account? <a>Sign in</a>"
                    description="CTA caption on homepage for signing in instead of creating a new account."
                    values={{
                      a: (text) => (
                        <Link
                          href="/login"
                          className="underline underline-offset-1 tracking-wide"
                        >
                          {text}
                        </Link>
                      ),
                    }}
                  />
                </Text>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpSuggest;
