import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useEffect } from 'react';
import { Button } from '../../atoms/button/button';
import InputText from '../../atoms/input-text/input-text';
import { Text } from '../../atoms/text/text';
import Modal from '../../molecules/modal/modal';
import useForgotPasswordForm from '@hooks/use-forgot-password-form';
import { getElementError } from '@hooks/use-form-validation';
import { Heading } from '@components/atoms/heading/heading';
import FormErrorMessage from '@components/molecules/form-error-message/form-error-message';

const messages = defineMessages({
  labelEmail: {
    id: 'zZUUCX',
    defaultMessage: 'Email',
    description: 'Form label for email on forgot password form.',
  },
});

interface Props {
  isOpen: boolean;
  close: () => void;
  email?: string;
}

export const ForgotPassword = ({ isOpen, close, email }: Props) => {
  const intl = useIntl();
  const {
    values,
    handleChange,
    handleSubmit,
    hasSubmitted,
    errors,
    hasErrors,
    isSubmitting,
    submitError,
    submitSuccessful,
    reset,
    setValues,
  } = useForgotPasswordForm();
  const getError = getElementError(errors, hasSubmitted);

  useEffect(() => {
    if (isOpen && email) {
      setValues((v) => ({ ...v, email }));
    }
  }, [email, isOpen, setValues]);

  const handleClose = () => {
    setTimeout(reset, 200);
    close();
  };
  return (
    <Modal
      size="S"
      isOpen={isOpen}
      onClose={() => {
        handleClose();
      }}
    >
      <form method="post" onSubmit={handleSubmit} className="container-xs">
        <Heading variant="h3" color="nad-blue" className="text-center">
          <FormattedMessage
            id="15cnev"
            defaultMessage="Forgot password"
            description="Title of forgot password modal."
          />
        </Heading>
        <Text size="XS" color="nad-alps-night" className="my-5">
          <FormattedMessage
            id="Q2Fwz7"
            defaultMessage="Enter the email linked to your account. We will send an authentication code to enable you to sign in and reset your password."
            description="Message within forgot password modal."
          />
        </Text>
        <InputText
          name="email"
          value={values.email}
          onChange={handleChange}
          label={intl.formatMessage(messages.labelEmail)}
          error={getError('email')}
          autoComplete="off"
          required
        />
        <div className="flex flex-col items-center">
          <FormErrorMessage className="mt-10" errors={submitError} />
          <Button
            width="large"
            isLoading={isSubmitting}
            disabled={isSubmitting || (hasSubmitted && hasErrors)}
            className="mt-10"
          >
            <FormattedMessage
              id="wDQcnH"
              defaultMessage="Submit"
              description="Submit button."
            />
          </Button>
          <Button
            width="large"
            type="button"
            variant="link"
            onClick={() => close()}
          >
            <FormattedMessage
              id="qz9XeG"
              defaultMessage="Cancel"
              description="Cancel button"
            />
          </Button>
        </div>
      </form>
    </Modal>
  );
};
