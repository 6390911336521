import React, { useRef, useState, useEffect, forwardRef } from 'react';
import classNames from 'classnames';

export interface VerificationInputProps {
  value?: string;
  length?: number;
  validChars?: string;
  placeholder?: string;
  autoFocus?: boolean;
  passwordMode?: boolean;
  inputProps?: object;
  containerProps?: object;
  classNames?: {
    container?: string;
    character?: string;
    characterInactive?: string;
    characterSelected?: string;
  };
  onChange?: (value: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onComplete?: (value: string) => void;
}

const VerificationInput = forwardRef<HTMLInputElement, VerificationInputProps>(
  (
    {
      value,
      length = 6,
      validChars = 'A-Za-z0-9',
      placeholder = '·',
      autoFocus = false,
      passwordMode,
      classNames: classes = {},
      onChange,
      onFocus,
      onBlur,
      onComplete,
    },
    ref
  ) => {
    const [localValue, setLocalValue] = useState('');
    const [isActive, setActive] = useState(false);

    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
      if (autoFocus) {
        inputRef.current?.focus();
      }
    }, [autoFocus]);

    const handleClick = () => {
      inputRef.current?.focus();
    };

    const onChangeHandler = (value: string) => {
      if (forceUppercase) {
        value = value.toUpperCase();
      }

      if (RegExp(`^[${validChars}]{0,${length}}$`).test(value)) {
        if (onChange) {
          onChange?.(value);
        }
        setLocalValue(value);

        if (value.length === length) {
          onComplete?.(value);
        }
      }
    };

    const forceUppercase = length < 10;

    const getValue = () => {
      const v = (value ?? localValue).slice(0, length);
      if (forceUppercase) {
        return v.toUpperCase();
      }
      return v;
    };

    const containerClasses = length <= 6 ? 'gap-3' : 'gap-1 sm:gap-3';
    const characterClasses = length <= 6 ? 'text-3xl' : 'text-xl sm:text-2xl';

    return (
      <div className="vi__wrapper">
        <input
          aria-label="verification input"
          spellCheck={false}
          value={getValue()}
          onPaste={(e) => {
            const text = e.clipboardData
              .getData('Text')
              .replace(/[-]/g, '')
              .slice(0, length);
            onChangeHandler(text);
          }}
          onChange={(event) => {
            onChangeHandler(event.target.value.replace(/\s/g, ''));
          }}
          ref={(node) => {
            inputRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          className={`vi focus:outline-none focus:ring-0`}
          onKeyDown={(event) => {
            if (
              ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'].includes(
                event.key
              )
            ) {
              // do not allow to change cursor position
              event.preventDefault();
            }
          }}
          onFocus={() => {
            setActive(true);
            onFocus?.();
          }}
          onBlur={() => {
            setActive(false);
            onBlur?.();
          }}
          onSelect={(e) => {
            const target = e.target as HTMLInputElement;
            const val = target.value;
            target.setSelectionRange(val.length, val.length);
          }}
          type={passwordMode ? 'password' : 'text'}
        />
        <div
          data-testid="container"
          className={classNames(
            'vi__container',
            classes.container,
            containerClasses,
            'w-full'
          )}
          onClick={() => inputRef.current?.focus()}
        >
          {[...Array(length)].map((_, i) => (
            <>
              <div
                className={classNames(
                  'vi__character',
                  classes.character,
                  'text-nad-blue font-bold',
                  characterClasses,
                  {
                    'vi__character--selected':
                      (getValue().length === i ||
                        (getValue().length === i + 1 && length === i + 1)) &&
                      isActive,
                    [classes.characterSelected!]:
                      (getValue().length === i ||
                        (getValue().length === i + 1 && length === i + 1)) &&
                      isActive,
                    'vi__character--inactive': getValue().length < i,
                    [classes.characterInactive!]: getValue().length < i,
                  }
                )}
                onClick={handleClick}
                id={`field-${i}`}
                data-testid={`character-${i}`}
                key={i}
              >
                {passwordMode && getValue()[i]
                  ? '*'
                  : getValue()[i] || placeholder}
              </div>
              {length === 9 && i % 3 === 2 && i !== 8 && (
                <span className="bg-nad-blue w-2 h-1 sm:w-4 sm:h-2 self-center"></span>
              )}
            </>
          ))}
        </div>
      </div>
    );
  }
);

VerificationInput.displayName = 'VerificationInput';

export default VerificationInput;
