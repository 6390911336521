import { InputHTMLAttributes, ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormError, getErrorMessage } from '@hooks/use-form-validation';
import { Text } from '@components/atoms/text/text';
import { useHelpscout } from '@hooks/use-helpscout';
import { useCurrentUser } from '@hooks/use-user';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string | ReactNode;
  labelBadge?: string | ReactNode;
  className?: string;
  checked?: boolean;
  textClassName?: string;
  extra?: ReactNode;
  error?: FormError;
  labelFirst?: boolean;
}

const Checkbox = ({
  label,
  labelBadge,
  checked,
  className = '',
  textClassName = 'text-nad-alps-night',
  error,
  labelFirst = false,
  ...props
}: Props) => {
  const intl = useIntl();
  const { openContactForm } = useHelpscout();
  const { data: user } = useCurrentUser();

  const labelContent = label ? (
    <div
      className={`leading-6 ${
        labelBadge
          ? 'w-full flex flex-col min-[500px]:flex-row justify-between min-[500px]:gap-2'
          : ''
      }`}
    >
      <span>{label}</span>
      {labelBadge && <span>{labelBadge}</span>}
    </div>
  ) : (
    <></>
  );

  const checkbox = (
    <input
      type="checkbox"
      checked={checked}
      className={`mt-1 w-4 h-4 checked:bg-nad-blue text-nad-blue border-nad-blue border-2 disabled:checked:bg-nad-alps-night-2 disabled:cursor-not-allowed disabled:border-nad-alps-night-2 cursor-pointer ${className}`}
      {...props}
    />
  );

  return (
    <div>
      <label
        htmlFor={props.id}
        className={`flex flex-row items-start gap-2 text-sm ${
          props.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        } ${error ? 'text-nad-scarlett' : textClassName}`}
      >
        {labelFirst ? (
          <>
            {labelContent}
            {checkbox}
          </>
        ) : (
          <>
            {checkbox}
            {labelContent}
          </>
        )}
        {props.extra && props.extra}
      </label>
      {error && error.type && (
        <Text size="S" color="nad-scarlett" className="mt-2">
          {error.type === 'privacyPolicy' && (
            <FormattedMessage
              id="GzqNck"
              defaultMessage="You must check this box to continue donating. Please <a>contact AdventistGiving</a> if you have any questions. "
              description="Error message shown on donation page if the user has not accepted the privacy policy."
              values={{
                a: (text: ReactNode) => (
                  <button
                    onClick={() => openContactForm({}, user)}
                    className="underline"
                  >
                    {text}
                  </button>
                ),
              }}
            />
          )}
          {error.type !== 'privacyPolicy' && getErrorMessage(intl, error)}
        </Text>
      )}
    </div>
  );
};

export default Checkbox;
