import Image from 'next/image';
import Slider from 'react-slick';
import { useRef } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import LoginFormHomepage from '../login-form-homepage/login-form-homepage';

import { Button } from '../../atoms/button/button';
import mainImage from '../../../public/noprecache/img/home-main.jpg';
import { useCurrentUser } from '@hooks/use-user';

import PaginationButton from '@components/molecules/pagination-button/pagination-button';
import HomepageWelcome from '@components/molecules/homepage-welcome/homepage-welcome';
import { Heading } from '@components/atoms/heading/heading';
import { Text } from '@components/atoms/text/text';
import { HeadingUppercase } from '@components/atoms/heading-uppercase/heading-uppercase';
import { OfferingOfTheWeek } from '@models/offering-of-the-week';
dayjs.extend(localizedFormat);

const messages = defineMessages({
  offeringTitle: {
    id: 'uE+0l6',
    defaultMessage: 'Offering Of The Week',
    description: 'Title of the offering of the week section on the home page',
  },
});

interface Props {
  offeringOfTheWeek?: OfferingOfTheWeek;
}

const SliderHome1 = ({ offeringOfTheWeek: offering }: Props) => {
  const intl = useIntl();
  let sliderMobile = useRef<Slider>(null);
  let sliderDesktop = useRef<Slider>(null);

  const { data: user } = useCurrentUser();

  const offeringImage = offering?.picture_url
    ? offering?.picture_url
    : mainImage;

  const isLoggedIn = user && user.status !== 'guest';

  const sliderNext = () => {
    sliderDesktop && sliderDesktop.current && sliderDesktop.current.slickNext();
    sliderMobile && sliderMobile.current && sliderMobile.current.slickNext();
  };

  const sliderPrev = () => {
    sliderDesktop && sliderDesktop.current && sliderDesktop.current.slickPrev();
    sliderMobile && sliderMobile.current && sliderMobile.current.slickPrev();
  };

  return (
    <>
      <section
        className={`container mt-6 sm:w-8/12 lg:w-auto ${
          offering ? 'pb-10' : ''
        }`}
      >
        <div className="lg:grid grid-cols-12">
          <div className="relative hidden lg:block col-span-6 xl:col-span-7 lg:ml-break-out min-[1152px]:ml-[-64px] xl:ml-0">
            <div className="absolute w-full aspect-[527/549] 2xl:aspect-[550/500]">
              <Image
                sizes="(max-width: 768px) 100vw, 50vw"
                priority
                className="object-cover w-full h-full"
                layout="fill"
                src={offeringImage}
                alt=""
              />
              {[offering].length > 1 && (
                <div className={`xl:hidden flex flex-row-reverse gap-8 pt-6`}>
                  <PaginationButton
                    disabled={!offering}
                    variant="next"
                    ariaType="slide"
                    color="white"
                    onClick={() => {
                      sliderNext();
                    }}
                    data-testid="slider-home-pagination-prev-1"
                  />
                  <PaginationButton
                    disabled={!offering}
                    variant="prev"
                    ariaType="slide"
                    color="white"
                    onClick={() => {
                      sliderPrev();
                    }}
                    data-testid="slider-home-pagination-next-1"
                  />
                </div>
              )}
            </div>
          </div>
          <div className="mb-10 md:mb-20 lg:mb-14 lg:ml-12 col-span-6 xl:col-span-5">
            {isLoggedIn ? (
              <div
                className="w-full h-full flex items-center lg:py-28"
                data-testid="home-message-sign-in"
              >
                <div className="space-y-8">
                  <HomepageWelcome includeGuestCTA={false} />
                  <div className="flex flex-col sm:flex-row">
                    <Button
                      as="link"
                      size="small"
                      href="/account"
                      data-testid="link-go-to-dashboard"
                    >
                      <FormattedMessage
                        id="REJkRR"
                        defaultMessage="Go To Dashboard"
                        description="Go to dashboard button"
                      />
                    </Button>
                    <Button
                      as="link"
                      variant="link"
                      size="small"
                      href="/logout"
                      data-testid="link-logout"
                    >
                      <FormattedMessage
                        id="vZn6l3"
                        defaultMessage="Log Out"
                        description="Log out button"
                      />
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <LoginFormHomepage />
            )}
          </div>
        </div>
      </section>
      <section className="hidden lg:block bg-nad-blue pt-14">
        <div className="container">
          <div className="grid grid-cols-12">
            <div className="col-span-6 xl:col-span-7"></div>
            <div
              className="col-span-6 xl:col-span-5 ml-12 lg:min-h-[14rem]"
              data-testid="slider-home-container"
            >
              <Slider
                ref={sliderDesktop}
                dots={false}
                arrows={false}
                slidesToShow={1}
                slidesToScroll={1}
              >
                {offering &&
                  [offering].map((item) => (
                    <div
                      key={item.title}
                      className="slide w-full h-full text-nad-white space-y-6"
                      data-testid="slider-home-item"
                    >
                      <div className="space-y-4">
                        <HeadingUppercase
                          variant="h6"
                          as="h2"
                          className="space-x-3"
                        >
                          <span>
                            {intl.formatMessage(messages.offeringTitle)}
                          </span>
                          <span>·</span>
                          <span>
                            {dayjs(item.for_sabbath).format('MMMM D')}
                          </span>
                        </HeadingUppercase>
                        <Heading variant="h4" as="h3">
                          {item.title}
                        </Heading>
                      </div>
                      <Text
                        size="S"
                        className="font-serif"
                        dangerouslySetInnerHTML={{
                          __html: item.description,
                        }}
                      ></Text>
                    </div>
                  ))}
              </Slider>
              <div
                className={`hidden xl:flex relative space-x-8 mt-2 ${
                  [offering].length <= 1 ? 'invisible' : ''
                }`}
              >
                <PaginationButton
                  disabled={!offering}
                  variant="prev"
                  ariaType="slide"
                  color="white"
                  onClick={() => {
                    sliderPrev();
                  }}
                  data-testid="slider-home-pagination-prev-2"
                />
                <PaginationButton
                  disabled={!offering}
                  variant="next"
                  ariaType="slide"
                  color="white"
                  onClick={() => {
                    sliderNext();
                  }}
                  data-testid="slider-home-pagination-next-2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="lg:hidden bg-nad-blue">
        <div className="md:container">
          <div className="mx-auto relative aspect-[414/376] sm:aspect-[536/376]">
            <Image
              sizes="(max-width: 768px) 100vw, 90vw"
              priority
              className="object-cover w-full h-full md:absolute md:top-[-40px]"
              layout="fill"
              src={offeringImage}
              alt=""
            />
          </div>
        </div>
        <div className="container mt-6 md:mt-0">
          <Slider
            ref={sliderMobile}
            dots={false}
            arrows={false}
            slidesToShow={1}
            slidesToScroll={1}
          >
            {offering &&
              [offering].map((item: any) => (
                <div
                  key={item.title}
                  className="slide w-full h-full text-nad-white space-y-4"
                >
                  <div className="space-y-3">
                    <HeadingUppercase
                      variant="h6"
                      as="h2"
                      className="space-x-3"
                    >
                      <span>{intl.formatMessage(messages.offeringTitle)}</span>
                      <span>·</span>
                      <span>{dayjs(item.for_sabbath).format('MMMM D')}</span>
                    </HeadingUppercase>
                    <Heading variant="h4" as="h3">
                      {item.title}
                    </Heading>
                  </div>
                  <Text
                    size="S"
                    className="font-serif"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  ></Text>
                </div>
              ))}
          </Slider>
          {[offering].length > 1 && (
            <div className="flex relative space-x-8 pt-6 sm:justify-start">
              <PaginationButton
                disabled={!offering}
                variant="prev"
                ariaType="slide"
                color="white"
                onClick={() => {
                  sliderPrev();
                }}
                data-testid="slider-home-pagination-prev-3"
              />
              <PaginationButton
                disabled={!offering}
                variant="next"
                ariaType="slide"
                color="white"
                onClick={() => {
                  sliderNext();
                }}
                data-testid="slider-home-pagination-next-3"
              />
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default SliderHome1;
