import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { CardImage, CardImageIcon } from '../../atoms/card-image/card-image';
import { Heading } from '@components/atoms/heading/heading';

const messages = defineMessages({
  focusLocal: {
    id: 'aGxfxC',
    defaultMessage:
      'The Lord calls us to love our neighbor as thyself. These offerings go toward loving our neighbors by supporting the local church ministries, operations, and surrounding community.',
    description: 'Description of local offerings on focus area of homepage.',
  },
  focusConferece: {
    id: 'OFVMTK',
    defaultMessage:
      'Conferences and unions aid in managing churches at the regional level. Your donation helps strengthen multiple churches through regional logistics, projects and evangelism.',
    description:
      'Description of conference offerings on focus area of homepage.',
  },
  focusWorld: {
    id: 'jVGEVf',
    defaultMessage:
      'Giving toward World Offerings supports global organizations, mission work, and other Adventist  divisions outside North America.',
    description: 'Description of world offerings on focus area of homepage.',
  },
  focusLocalTitle: {
    id: 'QPPXZm',
    defaultMessage: 'Local Offerings',
    description: 'Title for local offerings on focus area of homepage.',
  },
  focusConferenceTitle: {
    id: 'f8E6Tk',
    defaultMessage: 'Conference/Union Offerings',
    description: 'Title for conference offerings on focus area of homepage.',
  },
  focusWorldTitle: {
    id: 'J3W8xH',
    defaultMessage: 'World Offerings',
    description: 'Title for world offerings on focus area of homepage.',
  },
});

interface CardImage {
  image: string;
  imageMobile: string;
  icon: CardImageIcon;
  title: string;
  paragraph: string;
}

const FocusArea = () => {
  const intl = useIntl();
  const cards: CardImage[] = [
    {
      image: '/noprecache/img/home-local-offerings.jpg',
      imageMobile: '/noprecache/img/home-local-offerings-mobile.jpg',

      icon: 'church',
      title: intl.formatMessage(messages.focusLocalTitle),
      paragraph: intl.formatMessage(messages.focusLocal),
    },
    {
      image: '/noprecache/img/home-conference-offerings.jpg',
      imageMobile: '/noprecache/img/home-conference-offerings-mobile.jpg',

      icon: 'build',
      title: intl.formatMessage(messages.focusConferenceTitle),
      paragraph: intl.formatMessage(messages.focusConferece),
    },
    {
      image: '/noprecache/img/home-world-offerings.jpg',
      imageMobile: '/noprecache/img/home-world-offerings-mobile.jpg',

      icon: 'world',
      title: intl.formatMessage(messages.focusWorldTitle),
      paragraph: intl.formatMessage(messages.focusWorld),
    },
  ];
  return (
    <section className="container mb-36 mt-24">
      <div className="flex flex-col my-2">
        <Heading
          variant="h2"
          as="h2"
          color="nad-blue"
          className="lg:max-w-[690px] leading-[3rem]"
        >
          <FormattedMessage
            id="XWyOVR"
            defaultMessage="The North American Division focuses on these areas for Offerings:"
            description="Heading for card section on homepage."
          />
        </Heading>
        <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-10 mt-10 ">
          {cards.map((card, idx) => (
            <CardImage {...card} key={`card_${idx}`} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FocusArea;
