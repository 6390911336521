import React from 'react';
import { FormattedMessage } from 'react-intl';

import PaginationButton from '../pagination-button/pagination-button';
import { Heading } from '@components/atoms/heading/heading';

interface Props {
  current?: number;
  totalItems?: number;
  changeSlide?: (direction: string) => void;
  customNext?: () => void | undefined;
  customPrev?: () => void | undefined;
  showTitle?: boolean;
  showControl?: boolean;
}

const SliderHome2Control = ({
  current = 1,
  totalItems = 6,
  changeSlide = () => {},
  showTitle = true,
  showControl = true,
  customPrev = undefined,
  customNext = undefined,
}: Props) => {
  return (
    <div className="text-left lg:text-center self-start my-4 lg:my-0 ">
      {showTitle && (
        <Heading variant="h4" as="h2" color="nad-white" className="lg:text-m">
          <FormattedMessage
            id="ebBymm"
            defaultMessage="What are tithe & offerings?"
            description="Heading for slider on homepage."
          />
        </Heading>
      )}
      {showControl && (
        <div>
          <div className="hidden lg:block">
            <Heading variant="h1" as="h3" color="nad-white">
              {customPrev != null && customNext != null ? current : current + 1}
              /{totalItems}
            </Heading>
          </div>
          <div className="flex flex-row items-center justify-between lg:justify-center space-x-8 mt-6">
            <PaginationButton
              variant="prev"
              color="white"
              ariaType="slide"
              disabled={customPrev ? current <= 1 : current < 1}
              onClick={() => {
                if (customPrev != null) {
                  customPrev();
                } else {
                  changeSlide('LEFT');
                }
              }}
              data-testid="slider-home2-prev"
            />
            <div className="block lg:hidden">
              <Heading
                variant="h1"
                as="h3"
                color="nad-white"
                className="leading-none"
              >
                {customPrev != null && customNext != null
                  ? current
                  : current + 1}
                /{totalItems}
              </Heading>
            </div>
            <PaginationButton
              variant="next"
              color="white"
              ariaType="slide"
              disabled={
                customPrev != null && customNext != null
                  ? current >= totalItems
                  : current >= totalItems - 1
              }
              onClick={() => {
                if (customNext != null) {
                  customNext();
                } else {
                  changeSlide('RIGHT');
                }
              }}
              data-testid="slider-home2-next"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default SliderHome2Control;
